import axios from 'axios';
import Cookies from 'js-cookie';
import { COOKIE_ASSIGN_FEATURE, COOKIE_SWITCH_USER } from '../src/const/cookies';
import { LOGOUT } from '../src/const/routes';

/**
 * @param url
 * @param token
 * @param options
 * @return {Promise<any>}
 */
export default async function authRequest(url, token, options = { query: {}, body: {}, headers: {}, method: 'get' }) {
  try {
    const switchUser = Cookies.get(COOKIE_SWITCH_USER);
    if (switchUser) {
      options.query = options.query || {};
      options.query[COOKIE_SWITCH_USER] = switchUser;
    }

    const assignFeature = Cookies.get(COOKIE_ASSIGN_FEATURE);
    if (assignFeature) {
      options.query = options.query || {};
      options.query['feature'] = assignFeature;
    }

    // TODO refactor this to extend axios interface and use API for this
    const { data } = await axios(url, {
      headers: { ...options.headers, Authorization: 'Bearer ' + token },
      params: options.query,
      data: options.body,
      method: options.method,
    });

    if (data) {
      return data;
    }

    const error = new Error('Something went wrong: ' + url);
    error.data = data;

    throw error;
  } catch (error) {
    if (error?.response?.status === 401) {
      window.location.href = LOGOUT;
      return;
    }

    if (!error.data) {
      error.data = { message: error.message, ...(error?.response?.data || {}) };
    }
    throw error;
  }
}
