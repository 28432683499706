const tzList = [
  {
    offset: 660, //-11:00
    tz: 'Pacific/Midway',
  },
  {
    offset: 600, //-10:00
    tz: 'Pacific/Honolulu',
  },
  {
    offset: 570, //-09:30
    tz: 'Pacific/Marquesas',
  },
  {
    offset: 540, //-09:00
    tz: 'America/Juneau',
  },
  {
    offset: 480, //-08:00
    tz: 'America/Los_Angeles',
  },
  {
    offset: 420, //-07:00
    tz: 'America/Boise',
  },
  {
    offset: 360, //-06:00
    tz: 'America/Chicago',
  },
  {
    offset: 300, //-05:00
    tz: 'America/New_York',
  },
  {
    offset: 240, //-04:00
    tz: 'America/Caracas',
  },
  {
    offset: 210, //-03:30
    tz: 'America/St_Johns',
  },
  {
    offset: 180, //-03:00
    tz: 'America/Araguaina',
  },
  {
    offset: 120, //-02:00
    tz: 'Atlantic/South_Georgia',
  },
  {
    offset: 60, //-01:00
    tz: 'Atlantic/Azores',
  },
  {
    offset: 0, //00:00
    tz: 'Europe/London',
  },
  {
    offset: -60, //+01:00
    tz: 'Africa/Algiers',
  },
  {
    offset: -120, //+02:00
    tz: 'Africa/Cairo',
  },
  {
    offset: -180, //+03:00
    tz: 'Asia/Baghdad',
  },
  {
    offset: -210, //+03:30
    tz: 'Asia/Tehran',
  },
  {
    offset: -240, //+04:00
    tz: 'Asia/Baku',
  },
  {
    offset: -270, //+04:30
    tz: 'Asia/Kabul',
  },
  {
    offset: -300, //+05:00
    tz: 'Asia/Aqtau',
  },
  {
    offset: -330, //+05:30
    tz: 'Asia/Colombo',
  },
  {
    offset: -345, //+05:45
    tz: 'Asia/Kathmandu',
  },
  {
    offset: -360, //+06:00
    tz: 'Asia/Almaty',
  },
  {
    offset: -390, //+06:30
    tz: 'Asia/Yangon',
  },
  {
    offset: -420, //+07:00
    tz: 'Asia/Bangkok',
  },
  {
    offset: -480, //+08:00
    tz: 'Asia/Hong_Kong',
  },
  {
    offset: -525, //+08:45
    tz: 'Australia/Eucla',
  },
  {
    offset: -540, //+09:00
    tz: 'Asia/Tokyo',
  },
  {
    offset: -570, //+09:30
    tz: 'Australia/Adelaide',
  },
  {
    offset: -600, //+10:00
    tz: 'Asia/Vladivostok',
  },
  {
    offset: -630, //+10:30
    tz: 'Australia/Lord_Howe',
  },
  {
    offset: -660, //+11:00
    tz: 'Pacific/Guadalcanal',
  },
  {
    offset: -720, //+12:00
    tz: 'Asia/Kamchatka',
  },
  {
    offset: -765, //+12:45
    tz: 'Pacific/Chatham',
  },
  {
    offset: -780, //+13:00
    tz: 'Pacific/Enderbury',
  },
  {
    offset: -840, //+14:00
    tz: 'Pacific/Kiritimati',
  },
];

const zeroPad = (num, places) => String(num).padStart(places, '0');

export const getCurrentTimezone = () =>
  tzList.find(({ offset }) => offset === new Date().getTimezoneOffset())?.tz || 'Europe/London';

export const durationToCountdownFormat = (duration) => {
  let hours = duration && duration.days() * 24 + duration.hours();
  const hh = duration && zeroPad(hours, 2);
  const mm = duration && zeroPad(duration.minutes(), 2);
  const ss = duration && zeroPad(duration.seconds(), 2);

  return `${hh || '00'}:${mm || '00'}:${ss || '00'}`;
};
