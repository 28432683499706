import Cookies from 'js-cookie';

export default function retrieveTracks() {
  return {
    utm_source: Cookies.get('utm_source') || null,
    utm_medium: Cookies.get('utm_medium') || null,
    utm_campaign: Cookies.get('utm_campaign') || null,
    affiliate_reference: Cookies.get('affiliate_reference') || null,
    clickId: Cookies.get('clickId') || null,
    cpid: Cookies.get('cpid') || null,
  };
}
