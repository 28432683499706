import {
  INCREMENT_PROJECT_VISITORS,
  INCREMENT_PROJECT_VISITS,
  INCREMENT_VISITORS,
  INCREMENT_VISITS,
  LOAD_AFFILIATE_BALANCE,
  LOAD_APP_STATE,
  LOAD_DASHBOARD_METRICS,
  LOAD_PACKAGE_INFO,
  LOAD_PACKAGES,
  LOAD_PAYMENT_METHODS,
  LOAD_PROJECT_PANEL,
  LOAD_PROJECTS,
  LOAD_TRAFFIC_STATUS,
  PUSH_VISIT,
  TICK_MINUTE,
  UPDATE_PROJECT_STATUS,
} from 'src/const/actionTypes';
import axios from 'axios';
import {
  API_ACTIVE_PACKAGE,
  API_DASHBOARD,
  API_PROJECTS,
  INTERNAL_API_SESSION,
  API_PACKAGES_FOR_MAIN_PAGE,
  API_PAYMENT_METHODS,
  API_ME,
  API_ME_FEATURE,
  API_AFFILIATE_BALANCE,
} from 'src/const/routes';
import moment from 'moment';
import authRequest from 'lib/authRequest';
import _ from 'lodash';
import { loadFeatures, loadUser, pushNotifier, removeNotifier } from './user';
import { WARM_UP_ENABLED } from 'src/const/features';
import { getCurrentTimezone } from 'src/utils/date';
import { forCondition } from '../utils/wait';
import { INACTIVE, PAUSED } from '../const/projectStatuses';
import { PACKAGE_EXPIRED, WARM_UP } from '../const/notifiers';
import { initCountriesMap } from '../utils/geo';
import { getProjects, getTrafficStatus } from '../api/projects';
import { LAST_24H, MONTH, TODAY, WEEK, YESTERDAY } from '../const/periodsOfTime';
import retrieveTracks from 'lib/retrieveTracks';

const { utm_campaign } = retrieveTracks();

const getUser = async (getState) => {
  return await forCondition(
    () => getState().user,
    (x) => {
      return x.loaded === true;
    }
  )();
};

export const getUserAfterToken = (getState) => {
  return forCondition(
    () => getState().user,
    ({ token }) => token
  )();
};

const isLoggedIn = async (getState) => {
  const user = await getUser(getState);
  return user.isLoggedIn;
};

export const loadAppState = () => async (dispatch, getState) => {
  const stateToInit = {};
  const user = await getUserAfterToken(getState);

  const [{ displayName, avatarUrl = '', login, roles = [], features = [], reference = '', countryCode = '' }] =
    await Promise.all([authRequest(API_ME, user.token)]);

  dispatch(
    loadUser({
      reference,
      username: displayName.split('@')[0],
      avatarUrl,
      email: login,
      countryCode,
      roles,
    })
  );
  dispatch(loadFeatures(features));
  dispatch(loadPackageInfo());
  dispatch(loadAffiliateBalance());
  dispatch(loadProjects(100000));

  // Background workers
  dispatch(loadTrafficStatusWorker());

  // init countries map
  initCountriesMap(user.token).catch(console.error);

  const warmUpFeature = features.find(({ type, active }) => type === WARM_UP_ENABLED && active);

  if (warmUpFeature) {
    if (!user.projectInitTimeout) {
      try {
        const projectInitTimeout = moment().add(1, 'minutes');
        await axios.put(INTERNAL_API_SESSION, { projectInitTimeout });
        user.projectInitTimeout = projectInitTimeout;
      } catch (err) {
        console.error(err.response);
      }
    } else if (moment().diff(moment(user.projectInitTimeout)) > 0) {
      authRequest(API_ME_FEATURE.replace('{feature}', WARM_UP_ENABLED), user.token, { method: 'DELETE' }).catch(
        console.error
      );
    }

    dispatch(
      pushNotifier(WARM_UP, {
        enabled: warmUpFeature.active,
        priority: warmUpFeature.priority,
        duration: moment.duration(moment(user.projectInitTimeout).diff(moment())),
      })
    );
  }

  dispatch({
    type: LOAD_APP_STATE,
    ...stateToInit,
  });
};

export const loadPackageInfo = () => async (dispatch, getState) => {
  const user = await getUserAfterToken(getState);

  const activePackage = await authRequest(API_ACTIVE_PACKAGE, user.token);

  if (!activePackage.active) {
    dispatch(pushNotifier(PACKAGE_EXPIRED, { enabled: true, priority: 100 }));
  } else if (activePackage.active) {
    dispatch(removeNotifier(PACKAGE_EXPIRED));
  }

  dispatch({
    type: LOAD_PACKAGE_INFO,
    packageInfo: {
      ...activePackage,
      ...activePackage.package,
    },
  });
};

export const loadAvailablePackages = (utmCampaignParam) => async (dispatch, getState) => {
  let rawPackages;
  const campaign = utmCampaignParam || utm_campaign;

  if (await isLoggedIn(getState)) {
    const user = await getUserAfterToken(getState);
    rawPackages = await authRequest(API_PACKAGES_FOR_MAIN_PAGE, user.token);
  } else {
    const { data: packages } = await axios.get(API_PACKAGES_FOR_MAIN_PAGE, {
      params: campaign ? { utm_campaign: campaign } : {},
    });
    rawPackages = packages;
  }

  const indexedData = {};

  for (let i = 0; rawPackages && i < rawPackages.length; i++) {
    const pack = rawPackages[i];

    const {
      id,
      code,
      price,
      priceWithoutDiscount,
      subscriptionPeriod,
      subscriptionTrialPrice,
      subscriptionRegularPrice,
    } = pack;

    if (indexedData[code]) {
      if (subscriptionPeriod === 'annually') {
        indexedData[code].priceAnnually = price;
        indexedData[code].priceAnnuallyWithoutDiscount = priceWithoutDiscount;
        indexedData[code].subscriptionTrialPriceAnnually = subscriptionTrialPrice;
        indexedData[code].subscriptionRegularPriceAnnually = subscriptionRegularPrice;
        indexedData[code].idAnnually = id;
      } else {
        indexedData[code] = { ...indexedData[code], ...pack };
      }
    } else {
      if (subscriptionPeriod === 'annually') {
        indexedData[code] = {
          idAnnually: id,
          priceAnnually: price,
          priceAnnuallyWithoutDiscount: priceWithoutDiscount,
          subscriptionTrialPriceAnnually: subscriptionTrialPrice,
          subscriptionRegularPriceAnnually: subscriptionRegularPrice,
        };
      } else {
        indexedData[code] = { ...pack };
      }
    }
  }

  dispatch({
    type: LOAD_PACKAGES,
    packages: Object.values(indexedData),
  });
};

export const loadProjects =
  (limit = 10, page = 1, query = { filterName: null, filterStatus: [] }) =>
  async (dispatch, getState) => {
    const user = await getUserAfterToken(getState);

    let criteria = {};
    if (query.filterName) {
      criteria.name = query.filterName;
    }
    if (query.filterStatus) {
      criteria.status = query.filterStatus;
    }

    const result = await getProjects(user.token, criteria, page, limit);

    console.log('Loaded %d of %d projects by criteria', result.data.length, result.total, criteria);

    const projects = _.keyBy(result.data, 'id');

    dispatch({ type: LOAD_PROJECTS, projects: projects, projectsTotal: result.total, projectsLoaded: true });
    dispatch(loadTrafficStatus());
  };

export const loadAffiliateBalance =
  (callback = () => {}) =>
  async (dispatch, getState) => {
    const user = await getUserAfterToken(getState);

    const balances = await authRequest(API_AFFILIATE_BALANCE, user.token);

    dispatch({
      type: LOAD_AFFILIATE_BALANCE,
      affiliateBalances: balances,
    });

    callback();
  };

export const loadPaymentMethods = () => async (dispatch, getState) => {
  const user = await getUserAfterToken(getState);

  const paymentMethods = await authRequest(API_PAYMENT_METHODS, user.token);

  dispatch({
    type: LOAD_PAYMENT_METHODS,
    paymentMethods,
  });
};

export const pushVisit = (visit) => (dispatch) => {
  dispatch({
    type: PUSH_VISIT,
    visit,
  });
};

export const incrementVisits = () => (dispatch) => {
  dispatch({
    type: INCREMENT_VISITS,
  });
};

export const incrementVisitors = () => (dispatch) => {
  dispatch({
    type: INCREMENT_VISITORS,
  });
};

export const loadDashboardMetrics = (period) => async (dispatch, getState) => {
  const user = await getUserAfterToken(getState);
  const timezone = getCurrentTimezone();

  let start, end;
  switch (period) {
    case WEEK:
      start = moment().startOf('isoWeek');
      end = moment().endOf('isoWeek');
      break;

    case MONTH:
      start = moment().startOf('month');
      end = moment().endOf('month');
      break;

    case TODAY:
      start = moment().startOf('day');
      end = moment().endOf('day');
      break;

    case YESTERDAY:
      start = moment().subtract(1, 'days').startOf('day');
      end = moment().subtract(1, 'days').endOf('day');
      break;

    case LAST_24H:
    default:
      start = moment().subtract(24, 'hours');
      end = moment();
  }

  const { recentVisits, counters, chart } = await authRequest(API_DASHBOARD, user.token, {
    query: {
      // projectId,
      timezone,
      // interval: 3600,
      from: start.format(),
      to: end.format(),
    },
  });

  dispatch({
    type: LOAD_DASHBOARD_METRICS,
    recentVisits: recentVisits.slice(0, 6),
    counters: counters,
    chart: chart,
  });
};

export const loadProjectPanel = (projectId) => async (dispatch, getState) => {
  const user = await getUserAfterToken(getState);
  const timezone = getCurrentTimezone();

  const [{ counters }, { chart }] = await Promise.all([
    authRequest(API_DASHBOARD, user.token, {
      query: {
        projectId,
        timezone,
        from: moment().startOf('day').format(),
        to: moment().endOf('day').format(),
      },
    }),
    // TODO move to chart route
    authRequest(API_DASHBOARD, user.token, {
      query: {
        projectId,
        timezone,
        from: moment().subtract(9, 'minutes').format(),
        to: moment().format(),
        interval: 60,
      },
    }),
  ]);

  dispatch({
    type: LOAD_PROJECT_PANEL,
    projectId,
    counters,
    chart,
  });
};

export const incrementProjectVisits = (projectId) => (dispatch) => {
  dispatch({
    type: INCREMENT_PROJECT_VISITS,
    projectId,
  });
};

export const incrementProjectVisitors = (projectId) => (dispatch) => {
  dispatch({
    type: INCREMENT_PROJECT_VISITORS,
    projectId,
  });
};

export const updateProjectStatus = (projectId, status) => (dispatch, getState) => {
  const state = getState();

  if (!state.app.projects[projectId]) {
    return;
  }

  // skip cases when project paused by us and stop event come from server
  if (state.app.projects[projectId].status === PAUSED && status === INACTIVE) {
    return;
  }

  dispatch(loadPackageInfo());

  dispatch({
    type: UPDATE_PROJECT_STATUS,
    projectId,
    status,
  });

  dispatch(loadTrafficStatus());
};

export const loadTrafficStatus = (projects) => async (dispatch, getState) => {
  const user = await getUserAfterToken(getState);

  if (!projects) {
    projects = await getState().app.projects;
  }
  if (projects.length === 0) {
    return;
  }

  const trafficStatus = await getTrafficStatus(user.token);

  dispatch({
    type: LOAD_TRAFFIC_STATUS,
    trafficStatus,
  });
};

let trafficStatusWorkerRun = false;
export const loadTrafficStatusWorker = () => async (dispatch, getState) => {
  if (!trafficStatusWorkerRun) {
    trafficStatusWorkerRun = true;
    setTimeout(async () => {
      console.debug('Traffic status worker: tick');
      await dispatch(loadTrafficStatus());
      trafficStatusWorkerRun = false;
      await dispatch(loadTrafficStatusWorker());
    }, 30000);
  }
};

export const tickMinute = () => (dispatch) => {
  dispatch({
    type: TICK_MINUTE,
    minute: moment(),
  });
};
