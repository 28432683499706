import authRequest from 'lib/authRequest';
import { API_GEO_CITIES, API_GEO_COUNTRY, API_GEO_STATES } from 'src/const/routes';
import _ from 'lodash';
import { forCondition } from './wait';
import countryCodeEmoji from 'country-code-emoji';
import { apiUrl } from 'src/config.client';

let countriesMap = {};
let initialized = false;
let countryList = [];
let countryOptions = [];

export const initCountriesMap = async (token, apiUrl = '') => {
  if (initialized) {
    return;
  }

  try {
    countryList = _.sortBy(await authRequest(apiUrl + API_GEO_COUNTRY, token), 'name');

    for (let i = 0; i < countryList.length; i++) {
      countryOptions.push({
        value: countryList[i].id,
        label: `${countryCodeEmoji(countryList[i].code)} ${countryList[i].name}`,
      });
    }

    countriesMap = _.keyBy(countryList, 'id');
    initialized = true;
  } catch (err) {
    console.error('Unable to load country list - ', err);
  }
};

export const getCountryList = () => {
  return countryList;
};

export const getCountryOptions = () => {
  return countryOptions;
};

export const getCountryById = async (id) => {
  await forCondition(
    () => initialized,
    (initialized) => initialized
  )();

  if (!countriesMap[id]) {
    throw new Error('Countries map not init yet or country with this id does not exist');
  }

  return countriesMap[id];
};

export const getStateByCountryCodeAndToken = async (countryCode, token, apiUrl = '', query = '') => {
  try {
    const states = await authRequest(apiUrl + API_GEO_STATES.replace('{countryCode}', countryCode), token, {
      query,
    });

    return _.keyBy(states, 'id');
  } catch (err) {
    console.error('Unable to load states list - ', err);

    return {};
  }
};

export const getCitiesByCountryCodeAndToken = async (countryCode, token, apiUrl = '', query = '') => {
  try {
    const states = await authRequest(apiUrl + API_GEO_CITIES.replace('{countryCode}', countryCode), token, {
      query,
    });

    return _.keyBy(states, 'id');
  } catch (err) {
    console.error('Unable to load cities list - ', err);

    return {};
  }
};
