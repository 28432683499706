import authRequest from 'lib/authRequest';

export const get = async (token, path, criteria = {}, page = 1, limit = 10) => {
  if (page < 1) {
    page = 1;
  }
  if (limit < 1) {
    limit = 1;
  } else if (limit > 100000) {
    limit = 100000;
  }

  const options = {
    query: {
      page: page,
      limit: limit,
    },
  };

  if (criteria) {
    options.query = {
      ...criteria,
      ...options.query,
    };
  }

  const response = await authRequest(path, token, options).catch((e) => console.error(e));

  if (!response) {
    throw new Error('Invalid request: ' + path);
  }

  return {
    data: response['hydra:member'] || [],
    total: response['hydra:totalItems'] || 0,
    limit: limit,
  };
};

export const getById = async (token, path, id) => {
  return await authRequest(path.replace('{id}', id), token).catch((e) => console.error(e));
};

export const create = async (token, path, data) => {
  try {
    return await authRequest(path, token, {
      method: 'POST',
      body: data,
    });
  } catch (err) {
    console.warn('Unable to create entity', err);
    return err.data;
  }
};

export const update = async (token, path, id, data) => {
  try {
    return await authRequest(path.replace('{id}', id), token, {
      method: 'PUT',
      body: data,
    });
  } catch (err) {
    console.warn('Unable to create entity', err);
    return err.data;
  }
};
