export const ACTIVE = 'active';
export const INACTIVE = 'inactive';
export const PAUSED = 'paused';
export const EXPIRED = 'expired';
export const ARCHIVED = 'archived';
export const RESTARTING = 'restarting';

// Virtual statuses. Based on traffic status
export const UPDATING = 'updating';
export const PARSING_PAGES = 'parsing_pages';
export const PARSING_FAILED = 'parsing_failed';
