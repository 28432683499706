import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/common/loader';
import styled from 'styled-components';
import { DropdownToggle } from 'reactstrap';

const Avatar = styled.span`
  display: inline-block;
  border-radius: 50%;
  background: linear-gradient(64.25deg, #5db8fb 15.64%, #35ea94 94.68%);
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  width: 32px;
  height: 32px;
  padding-top: 8px;
  margin-right: 10px;
`;

const Username = styled.span`
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`;

const UserMenu = styled(DropdownToggle)`
  background: none !important;
  border: none !important;

  &:focus,
  &:hover,
  &:active,
  &:target {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  &::after {
    vertical-align: 0;
    margin-left: 6px;
  }
`;

const ActiveUser = () => {
  const username = useSelector((state) => state.user.username);
  const email = useSelector((state) => state.user.email);

  useEffect(() => {
    if (email && username && typeof window !== 'undefined' && window.Tawk_API) {
      window.Tawk_API.onLoad = () => {
        window.Tawk_API.setAttributes({ name: username, email }, console.error);
      };
    }
  }, [email, username]);

  return (
    <Loader isLoading={!username}>
      <UserMenu caret={true}>
        <Avatar>{(username && username.substring(0, 2).toUpperCase()) || 'TB'}</Avatar>
        <Username>{username}</Username>
      </UserMenu>
    </Loader>
  );
};
export default ActiveUser;
