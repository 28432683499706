import { API_PROJECT_TRAFFIC_STATUS, API_PROJECTS } from 'src/const/routes';
import authRequest from 'lib/authRequest';
import { get } from './api';

export const getProjects = async (token, criteria = {}, page = 1, limit = 10) => {
  return await get(token, API_PROJECTS, criteria, page, limit);
};

export const getTrafficStatus = async (token, projectIds = []) => {
  if (!projectIds) {
    projectIds = [];
  }
  let url = API_PROJECT_TRAFFIC_STATUS;
  if (projectIds.length > 0) {
    url += '?ids=' + projectIds.join(',');
  }

  return await authRequest(url, token).catch(console.error);
};
