/**
 * @param {number} [delayMs=0]
 * @returns {Promise<void>}
 */
export const forMs = (delayMs = 0) => new Promise((res) => setTimeout(res, delayMs));

export const forCondition = (
  fn,
  resultCondition = (result) => typeof result !== 'undefined',
  retries = 50,
  delayMs = 100
) => {
  let attempts = retries;

  const retryable = async (...args) => {
    attempts -= 1;

    try {
      const result = await fn(...args);

      if (!resultCondition(result)) {
        throw new Error('Result not match conditions');
      }

      attempts = retries;

      return result;
    } catch (error) {
      if (!attempts) {
        attempts = retries;

        throw error;
      }

      await forMs(delayMs);

      return retryable(...args);
    }
  };

  return retryable;
};
