import React from 'react';
import styled from 'styled-components';

import spinnerSvg from 'src/assets/img/spinner.svg';

const Wrapper = styled.div`
  position: relative;
`;

const Spinner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${spinnerSvg.src});
  background-size: ${(props) => props.backgroundSize};
  background-repeat: no-repeat;
  background-position: center;
`;

const Loader = ({ children, isLoading, backgroundSize = 'contain' }) => {
  return (
    <Wrapper className="overflow-hidden">
      <Spinner style={{ visibility: isLoading ? 'visible' : 'hidden' }} backgroundSize={backgroundSize} />
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{children}</div>
    </Wrapper>
  );
};

export default Loader;
